import { createAsyncStateHook } from './utils'
import {
  getList,
  getListIndex,
  getListPinned,
  getListReadAlso,
  getListContent,
} from 'src/services/listServices'

import { getIcons } from 'src/services/reviewServices'

export const useList = createAsyncStateHook('list', getList)

export const useListIndex = createAsyncStateHook('listIndex', getListIndex)

export const useListReadAlso = createAsyncStateHook(
  'listReadAlso',
  getListReadAlso
)

export const useListPinned = createAsyncStateHook(
  'newsListPinned',
  getListPinned
)

export const useListContent = createAsyncStateHook(
  'listContent',
  getListContent
)

export const useListIcons = createAsyncStateHook('ListIcons', getIcons)
